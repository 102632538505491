/** @format */

.chart-wrapper {
  canvas {
    width: 100% !important;
  }
}
// scss-lint:disable QualifyingElement
base-chart.chart {
  display: block !important;
}
