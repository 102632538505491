/** @format */

.app-header.navbar {
  position: relative;
  flex-direction: row;
  height: $navbar-height;
  padding: 0;
  margin: 0;
  background-color: $navbar-bg;

  .navbar-brand {
    display: inline-block;
    width: 252px;
    height: $navbar-height;
    margin-right: 0;
    background-color: $sidebar-bg;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: $navbar-brand-logo-size;
  }

  .navbar-toggler {
    min-width: 50px;
    padding: $navbar-toggler-padding-y 0;

    &:hover .navbar-toggler-icon {
      background-image: $navbar-toggler-icon-hover;
    }
  }

  .navbar-toggler-icon {
    height: 23px;
    background-image: $navbar-toggler-icon;
  }

  .navbar-nav {
    flex-direction: row;
    align-items: center;
  }

  .nav-item {
    position: relative;
    min-width: 50px;
    margin: 0 !important;
    text-align: center;

    button {
      margin: 0 auto;
    }

    .nav-link {
      padding-top: 0;
      padding-bottom: 0;
      background: 0;
      border: 0;

      .badge {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -16px;
        margin-left: 0;
      }

      > .img-avatar {
        height: $navbar-height - 20px;
        margin: 0 10px;
      }
    }
  }

  .dropdown-menu {
    padding-bottom: 0;
    line-height: $line-height-base;
  }

  .dropdown-item {
    min-width: 180px;
  }
}

.navbar-brand {
  color: $navbar-active-color;
}

.navbar-nav {
  .nav-link {
    color: $navbar-color;
  }
}

.navbar-divider {
  background-color: rgba(0, 0, 0, 0.075);
}

@include media-breakpoint-up(lg) {
  .brand-minimized {
    .app-header.navbar {
      .navbar-brand {
        width: $navbar-brand-minimized-width;
        //background-image: $navbar-brand-minimized-logo;
      }
    }
  }
}
