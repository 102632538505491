/** @format */

.breadcrumb {
  color: #9e9e9e;
  background-color: $navbar-bg;
  padding-left: 30px;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.1);
  padding-bottom: 8px;
  .breadcrumb-item.active {
    color: #9e9e9e;
  }
  .breadcrumb-item + .breadcrumb-item::before {
    color: #9e9e9e;
    content: '>';
  }
  /*
  position: relative;
  @include borders($breadcrumb-borders);

  .navbar-toggler {
    min-width: auto;
    padding: 0;
    border: none;

    &.active .navbar-toggler-icon {
      color: $graana-green;
    }
  }

  .navbar-toggler-icon {
    height: 20px;
    vertical-align: top;
    color: $graana-red;
  }*/
}
