/** @format */

// NOTE: the order of these styles DO matter

// Will edit everything selected including everything between a range of dates
.SingleDatePicker {
  width: 146px;
  z-index: 100;
}
.DateRangePickerInput {
  padding-right: 0;
}
.CalendarDay__selected_span {
  background: #82e0aa; //background
  color: white; //text
  border: 1px solid red; //default styles include a border
}

// Will edit selected date or the endpoints of a range of dates
.CalendarDay__selected {
  background: red;
  color: white;
}

// Will edit when hovered over. _span style also has this property
.CalendarDay__selected:hover {
  background: orange;
  color: white;
}

// Will edit when the second date (end date) in a range of dates
// is not yet selected. Edits the dates between your mouse and said date
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: brown;
}
