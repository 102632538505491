/** @format */

// Here you can add other styles
.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  font-family: 'Rubik' !important;
  font-size: 14px !important;
}
.cus-phone .react-tel-input .country-list {
  max-height: 160px !important;
}
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  font-family: 'Rubik' !important;
  font-size: 15px !important;
}
.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: '#37a2f8' !important;
}
// Mui Date Picker style
.css-bkrceb-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
  background-color: #37a2f8 !important;
}
.css-dhopo2 {
  min-height: 250px !important;
}
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  font-family: 'Rubik' !important;
  border-radius: 8px !important;
}
.react-calendar button {
  font-family: 'Rubik' !important;
  font-size: 13px !important;
  font-weight: normal !important;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  border-radius: 50% !important;
  &:hover {
    background-color: rgb(235, 246, 255) !important;
  }
}
.modalDate .react-daterange-picker__calendar .react-calendar {
  right: 120px !important;
  position: absolute !important;
}
.modalDate .react-daterange-picker__wrapper {
  height: 52px !important;
  width: 319px !important;
  font-weight: normal !important;
  padding-top: 0px !important;
  border-radius: 8px !important;
  padding-left: 8px !important;
  border-color: rgb(194, 207, 214) !important;
}
.react-calendar__tile--now {
  background-color: white !important;
  border-radius: 50% !important;
  padding-top: 14px !important;
  padding-bottom: 14px !important;
  border: 1px solid black !important;
}
.datePicker .react-date-picker__inputGroup__divider {
  color: black !important;
}
.hidden .react-date-picker__inputGroup__divider {
  visibility: hidden !important;
}
.react-daterange-picker__inputGroup__leadingZero {
  color: black !important;
}
.react-calendar__month-view__days__day--weekend {
  color: black !important;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: black !important;
  visibility: hidden !important;
}
.react-calendar__tile--active {
  background-color: rgb(55, 162, 248) !important;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  opacity: 1 !important;
  border-radius: 50% !important;
  color: white !important;
  &:hover {
    color: black !important;
  }
}
abbr[title],
abbr[data-bs-original-title] {
  text-decoration: none;
  cursor: auto;
  text-decoration-skip-ink: none;
}
.react-calendar__month-view__weekdays {
  font-family: 'Rubik' !important;
  text-transform: capitalize !important;
  color: #9e9e9e !important;
}
.react-daterange-picker__inputGroup__input:invalid {
  background-color: transparent !important;
  visibility: hidden !important;
}
.react-daterange-picker__wrapper {
  height: 50px !important;
  width: 350px !important;
  font-weight: normal !important;
  padding-top: 0px !important;
  border-radius: 8px !important;
  border-color: rgb(194, 207, 214) !important;
}
.react-daterange-picker__button svg {
  color: #9e9e9e !important;
  font-weight: normal !important;
  font-size: 18px !important;
  &:hover {
    color: #37a2f8 !important;
  }
}
.react-daterange-picker__inputGroup__input {
  outline: none !important;
  caret-color: white !important;
}
.react-daterange-picker__calendar .react-calendar {
  border-radius: 4px !important;
  padding: 8px !important;
  margin-top: 2px !important;
  border-color: rgb(194, 207, 214) !important;
}
.css-bkrceb-MuiButtonBase-root-MuiPickersDay-root {
  font-family: 'Rubik' !important;
}
.css-195y93z-MuiButtonBase-root-MuiPickersDay-root {
  font-family: 'Rubik' !important;
}
.css-m1gykc.Mui-selected {
  background-color: #37a2f8 !important;
}
.css-m1gykc {
  font-family: 'Rubik' !important;
}
.css-l0iinn {
  font-family: 'Rubik' !important;
}
.css-1w13o7u-MuiTypography-root {
  font-family: 'Rubik' !important;
}
.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  color: #37a2f8 !important;
  font-family: 'Rubik' !important;
  margin-left: auto !important;
  margin-right: 0 !important;
  &:hover {
    background-color: #f6f6f6 !important;
  }
}
.css-10qkmc3 {
  font-family: 'Rubik' !important;
}
.css-ub1r1.Mui-selected {
  background-color: #37a2f8 !important;
  font-family: 'Rubik' !important;
}
.css-ub1r1 {
  font-family: 'Rubik' !important;
  &:hover {
    background-color: #ebf6ff !important;
  }
}
.css-1ujsas3 {
  color: #37a2f8 !important;
  font-family: 'Rubik' !important;
  margin-left: auto !important;
  margin-right: 0 !important;
  &:hover {
    background-color: #f6f6f6 !important;
  }
}
.cus-select .Select-placeholder {
  top: 6px !important;
}
.cus-select .Select-control .Select-multi-value-wrapper .Select-value {
  top: 6px !important;
}
.cus-select .Select-input {
  padding-top: 5px !important;
}
.cus-select .Select-clear {
  &:hover {
    color: #37a2f8 !important;
  }
}
.cus-select .Select-clear-zone {
  &:hover {
    color: #37a2f8 !important;
  }
}

.select_box {
  position: relative;
  padding: 10px 0;
}
.select_box:after {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #f00;
  position: absolute;
  top: 40%;
  right: 5px;
  content: '';
  z-index: 98;
}
.select_box select {
  width: 220px;
  border: 0;
  position: relative;
  z-index: 99;
  background: none;
}
.phoneRequired {
  .flag-dropdown {
    border-right: hidden !important;
    background-color: white !important;
  }
  .react-tel-input .flag-dropdown.open .selected-flag {
    z-index: 0 !important;
  }
}
.btn {
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  box-shadow: 1px 2px 14px 0px rgba(0, 0, 0, 0.1);
  font-weight: 600;
  z-index: 0;
  & ::placeholder {
    color: #9e9e9e;
    font-family: 'Rubik';
  }
}
.custom-lable {
  font-style: normal;
  font-weight: bold;
  font-size: 15.6px;
  line-height: 24px;
  /* identical to box height, or 154% */

  display: flex;
  align-items: center;
  letter-spacing: 0.15px;

  color: #000000;
}
.custom-modal-content {
  border-radius: 0.3rem;
  max-width: 420px;
  margin-left: 60px;
}
.modal-custom {
  max-height: 90vh;
  overflow-y: auto;
}
.custom-margin {
  margin-right: 55px;
  border-radius: 5px;
  margin-bottom: 3px;
}
.custom-react-select-city {
  z-index: 101;
}
.custom-react-select {
  z-index: 100;
}
.custom-date {
  .SingleDatePicker {
    display: inline-block;
  }
}
.custom-description-margin {
  margin-right: 40px;
  border-radius: 5px;
  margin-bottom: 3px;
}
.margin {
  margin-right: 0px;
  border-radius: 5px;
  margin-bottom: 3px;
}
.jazz-cash {
  width: 25px;
}
.easy-paisa {
  width: 25px;
  border-radius: 5px;
}
.btn-group {
  #caret {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    font-weight: 600;
  }
  & .dropdown-toggle {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    font-weight: 600;
  }
}
.dropdown-menu {
  z-index: 100;
  border-radius: 5px;
}
.nav-tabs {
  & .nav-item {
    & .active {
      border-top-left-radius: 7px !important;
      border-top-right-radius: 7px !important;
      border-top-color: rgba(158, 158, 158, 0.5) !important;
      border-left-color: rgba(158, 158, 158, 0.5) !important;
      border-right-color: rgba(158, 158, 158, 0.5) !important;
    }
  }
}

.table-responsive {
  border-radius: 10px;
  border-top-left-radius: 0px;
  box-shadow: 'none';
}

.additionalPhonesPopOver {
  // position : absolute;
  transform: none !important;
  min-width: 100px !important;
  width: 130px !important;
  & p {
    margin-bottom: 0px !important;
  }
}
.logo_bg_responsive {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logo_responsive {
  color: #ff0000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logo_bg_list {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logo_list {
  color: #ff0000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logo_bg_grid {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logo_grid {
  color: #ff0000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.btnImg img {
  filter: brightness(0) invert(0.5);
}

.premium_plus,
.premium,
.basic {
  height: 473px;
  overflow: hidden;
  border-radius: 0 !important;
  margin-bottom: 15px;
  & .tileHeader {
    display: none;
    background: #fafafa;
    width: 100%;
    padding: 10px 5px;
    & .tileType {
      width: 55%;
      float: left;
      & img {
        float: left;
        width: 30px;
        object-fit: contain;
      }
      & p {
        margin: 0;
        margin-left: 3px;
        font-weight: bold;
        font-size: 14px;
      }
    }
    & .verifiedListings {
      position: absolute;
      right: 0;
      top: 0;
      padding: 0;
      padding-top: 10px;
      width: 110px;
      float: none;
      border-left: 1px solid #ddd;
      height: 100%;
      & img {
        float: left;
        object-fit: contain;
        height: 18px;
        padding-left: 10px;
        padding-top: 2px;
      }
      & p {
        font-size: 14px;
        font-weight: bold;
        margin: 0;
      }
    }
  }
  & .imageContainer {
    & .nextArrow {
      opacity: 0;
    }

    & .prevArrow {
      opacity: 0;
    }
    width: 360px;
    float: left;
    position: relative;
    & .slickTile {
      width: 100%;
      height: 330px !important;
    }
  }
  & .priceTabView {
    display: none;
    width: 155px;
    position: absolute;
    top: 0;
    left: 0px;
    border-bottom-right-radius: 20px;
    opacity: 0.85;
    padding: 5px 12px;
    background: #fff;
    z-index: 10;
  }
  & .details {
    height: 330px;
    overflow: hidden;
    position: relative;
    border-left: 1px solid #dddddd;
    & .addedBy {
      position: absolute;
      left: 15px;
      bottom: 3px;
      margin: 5px 0;
      width: 55%;
    }
    & .tileTitle {
      width: 350px;
      padding-right: 0;
    }
    & .tileFeats {
      display: block;
      max-height: 52px;
      overflow: hidden;
      position: absolute;
      transition: 0.3s ease;
      top: 0;
      left: 15px;
      z-index: 1;
      width: calc(100% - 30px);
      background: #eee;
      & p {
        font-size: 12px;
        margin: 0;
        padding: 2.5px 0 5px;
        color: #444;
        text-transform: capitalize;
      }
      & .featType {
        color: #999;
        padding: 5px 0 2.5px;
      }
      & .fixedAmenHeight {
        height: 50px;
      }
    }
    & .clickToExpand {
      cursor: default;
      position: absolute;
      top: -18px;
      font-size: 10px;
      right: 20px;
      background: #eee;
      padding: 2px 5px;
    }
    & .feedbackBtn {
      position: absolute;
      bottom: 10px;
      right: 80px;
      padding: 8px 10px;
      font-size: 11px;
      background: #ff4148;
      border: none;
      border-radius: 3px;
      color: #fff;
    }
    & .price {
      overflow: hidden;
      text-align: right;
      padding-right: 15px;
      font-size: 20px;
    }
    & .description {
      text-align: inherit;
      margin-top: 10px;
      font-size: 13px;
    }
    & .descEllipses {
      display: -webkit-box;
      height: 100px;
      font-size: 14px;
      text-overflow: ellipsis;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    & .agencyLogo {
      border: none;
      width: 55px;
      height: 55px;
      position: absolute;
      bottom: 10px;
      right: 10px;
    }
  }
  & .footer {
    & .listTypeCont {
      width: 360px;
      float: left;
      display: block;
      border-top: 1px solid #ddd;
      position: relative;
      background-color: #f6f6f6;
      & img.listingType {
        width: 36px;
        float: left;
        object-fit: contain;
      }
      & h3 {
        margin: 0;
        line-height: 40px;
        font-size: 16px;
      }
      & .verifiedListings {
        position: absolute;
        right: 0;
        top: 10px;
        padding: 0;
        width: 90px;
        float: none;
        & img {
          float: left;
          object-fit: contain;
          width: 30px;
          height: 18px;
          padding-left: 10px;
        }
        & p {
          font-size: 14px;
          font-weight: bold;
          margin: 0;
        }
      }
    }
    & .btnCont {
      display: block;
      & button {
        position: relative;
        width: 33.33333333%;
        height: 40px;
        border-radius: 0;
        border: none;
        border-top: 1px solid #ddd;
      }
      & .btnImg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 110px;
        & img {
          float: left;
          height: 17px;
          width: 20px;
          object-fit: contain;
          margin-right: 8px;
          vertical-align: text-bottom;
        }
        & p {
          font-size: 12px;
          margin: 0;
        }
        & span.additionalPhones {
          border: 1px solid #ddd;
          background-color: #fff;
          color: #ff4148;
          border-radius: 10px;
          padding: 0px 6px;
          margin-left: 5px;
          display: inline;
        }
        // & span.additionalPhones {
        //   border: 1px solid #ddd;
        //   background-color: #fff;
        //   color: #ff4148;
        //   border-radius: 10px;
        //   padding: 0px 6px;
        //   margin-left: 5px;
        //   display: inline;
        // }
      }
    }
  }
}

.premium {
  border-bottom-color: #26a59a !important;
  & .tileHeader {
    border-bottom: 1px solid #ddd;
    & .tileType {
      & img {
        height: 18px;
        margin-right: 3px;
      }
      & p {
        color: #444;
      }
    }
    & .verifiedListings {
      color: #26a59a;
    }
  }
  & .priceTabView {
    color: #26a59a;
  }
  & .details {
    & .price {
      color: #26a59a;
    }
  }
  & .footer {
    border-bottom-color: #26a59a;
    & .listTypeCont {
      & img.listingType {
        margin-left: 6px;
        margin-right: 2px;
        margin-top: 8px;
        height: 20px;
      }
      & h3 {
        color: #26a59a;
      }
      & .verifiedListings {
        color: #fff;
        background: #26a59a;
      }
    }
    & .priceContainer {
      background-color: #fafafa;
      & .price2 {
        color: #26a59a !important;
      }
    }
  }
}

.premium_plus {
  border-bottom-color: #ff4148 !important;
  & .tileHeader {
    border-bottom: 1px solid #ddd;
    & .tileType {
      & img {
        height: 18px;
        margin-right: 3px;
      }
      & p {
        color: #444;
      }
    }
    & .verifiedListings {
      // color: #ff4148;
      color: #fff;
      background: #26a59a;
    }
  }
  & .priceTabView {
    color: #ff4148;
  }
  & .details {
    & .price {
      color: #ff4148;
    }
  }
  & .footer {
    border-bottom-color: #ff4148;
    & .listTypeCont {
      & img.listingType {
        margin-left: 6px;
        margin-right: 2px;
        margin-top: 9px;
        height: 20px;
      }
      & h3 {
        color: #ff4148;
      }
      & .verifiedListings {
        color: #ff4148;
      }
    }
    & .priceContainer {
      background-color: #fafafa;
      & .price2 {
        color: #ff4148 !important;
      }
    }
  }
}

.basic {
  border-bottom-color: #444 !important;
  & .tileHeader {
    border-bottom: 1px solid #ddd;
    & .tileType {
      & img {
        display: none;
      }
      & p {
        padding-left: 5px;
        color: #444;
      }
    }
    & .verifiedListings {
      // color: #444;
      color: #fff;
      background: #26a59a;
      // & img {
      //     filter: invert(1) opacity(0.7);
      // }
    }
  }
  & .priceTabView {
    color: #444;
  }
  & .details {
    & .price {
      color: #444;
    }
  }
  & .footer {
    border-bottom-color: #444;
    & .listTypeCont {
      & img.listingType {
        margin: 0 6px;
        height: 100%;
        width: 0;
      }
      & h3 {
        color: #444;
        height: 40px;
      }
      & .verifiedListings {
        color: #444;
      }
    }
    & .priceContainer {
      background-color: #f6f6f6;
      & .price2 {
        color: #444 !important;
      }
    }
  }
}

/* Grid CSS */

.grid {
  background: white;
  border-bottom: 2px solid;
  width: calc(33.33% - 15px);
  margin-left: 15px;
  float: left;
  & .tileHeader {
    display: flex;
    position: relative;
    min-height: 42px;
  }
  & .imageContainer,
  & .listTypeCont {
    width: 100%;
  }
  & .slickTile_cont {
    display: block !important;
  }
  & .slickTile {
    width: 100%;
    height: 230px !important;
  }
  & .details {
    height: 120px;
    width: 100%;
    border: none;
    border-top: 1px solid #ddd;
    border-left: none;
    & .addedBy {
      position: absolute;
      left: 10px;
      bottom: 3px;
      margin: 5px 0;
      width: calc(100% - 20px);
    }
    & .tileTitle {
      width: 100%;
      padding-right: 10px;
      padding-left: 10px;
    }
    & .feedbackBtn {
      display: none;
    }
    & .tileFeats {
      display: none;
    }
    & .clickToExpand {
      display: none;
    }
    & .description {
      display: none;
    }
    & .price {
      display: none;
    }
  }
  & .footer {
    & button {
      min-width: 0 !important;
      margin-right: 0;
      padding-top: 8px;
      border-radius: 0;
      border: none;
      border-top: 1px solid #ddd;
      border-bottom: 1px solid #ddd !important;
    }
    & .btnImg {
      & img {
        margin-right: 0 !important;
        height: 18px !important;
        width: 22px !important;
      }
    }
    & .priceContainer {
      & .price2 {
        font-size: 18px;
        margin: 0;
        padding: 0 10px;
        line-height: 38px !important;
      }
    }
  }
  & .btnCont {
    & .phoneBtn {
      width: 59.33333333% !important;
    }
    & .inquiryBtn {
      width: 20.33333333% !important;
      & .btnImg {
        width: auto !important;
      }
      & p {
        display: none;
      }
    }
    & .compareBtn_cont {
      display: inline;
    }
    & .compareBtn {
      width: 20.33333333% !important;
      & .btnImg {
        width: auto !important;
      }
    }
  }
}

.inquiryPopCont {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0 !important;
  left: 0 !important;
  z-index: 98;
  background: rgba(0, 0, 0, 0.2);
}
.inquiryPop {
  width: 0;
  margin: 0 auto;
  height: 100%;
  position: relative;
}
.inquiryPopup {
  width: 450px;
  margin: 0 -225px;
  z-index: 99;
  top: 45%;
  transform: translateY(-50%);
  left: 0;
  position: absolute;
  background: white;
  padding: 25px;
}
.phoneIconWrapper {
  height: 50px;
  margin-top: 10px;
  border: 1px solid #eeeeee;
  border-left: none;
  background-color: #fff;
  & i {
    font-size: 36px;
    margin-top: 5px;
  }
}

.numberPopCont {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0 !important;
  left: 0 !important;
  z-index: 98;
  background: rgba(0, 0, 0, 0.2);
}
.numberPop {
  width: 0;
  margin: 0 auto;
  height: 100%;
  position: relative;
}
.numberPopup {
  width: 450px;
  margin: 0 -225px;
  z-index: 99;
  top: 45%;
  transform: translateY(-50%);
  left: 0;
  position: absolute;
  background: white;
  padding: 25px;
  & input {
    height: 50px;
    margin-bottom: 10px !important;
  }
  & button {
    height: 50px;
  }
}
.numberPopupClose {
  margin: 0;
  width: 18px;
  height: 18px;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  opacity: 0.8;
}

.paddingChange {
  padding: 0 40px;
}

.getboxshadow {
  transition: 0.2s;
}
.selectedBtn {
  background: #ebebeb !important;
  & img {
    filter: invert(10%) grayscale(100%) brightness(375%) sepia(100%) hue-rotate(310deg)
      saturate(302%) contrast(2);
  }
}
.turnGreen {
  width: 15px;
  -webkit-filter: invert(40%) grayscale(100%) brightness(40%) sepia(100%) hue-rotate(1300deg)
    saturate(600%) contrast(2);
  filter: grayscale(100%) brightness(40%) sepia(100%) hue-rotate(130deg) saturate(800%)
    contrast(0.8);
}
.btnDisableCustom:disabled {
  background-color: #fafafa !important;
}
@media only screen and (min-width: 768px) {
  .getboxshadow:hover {
    box-shadow: 2px 2px 18px rgba(20, 20, 20, 0.14);
    & .prevArrow {
      opacity: 1;
    }
    & .nextArrow {
      opacity: 1;
    }
  }
}

/* Tablet CSS */

@media only screen and (max-width: 1199px) {
  .premium_plus,
  .premium,
  .basic {
    & .imageContainer {
      width: 290px;
      & .slickTile {
        width: 100% !important;
      }
    }
    & .priceTabView {
      display: inherit;
    }
    & .details {
      & .addedBy {
        width: calc(100% - 85px);
      }
      & .price {
        display: none;
      }
      & .feedbackBtn {
        bottom: 35px;
        right: auto !important;
        left: 15px;
      }
      & .agencyLogo {
        width: 50px;
        height: 50px;
      }
    }
    & .footer {
      & .listTypeCont {
        width: 290px;
      }
    }
  }
  .grid {
    width: calc(50% - 15px);
    & .slickTile {
      width: 100%;
    }
    & .details {
      height: 120px;
    }
  }
}

@media only screen and (max-width: 991px) {
  .grid {
    & .slickTile {
      width: 100%;
    }
  }
}

/* Mobile CSS */

@media only screen and (max-width: 767px) {
  .grid {
    width: 100%;
    margin-left: 0;
  }
  .slickTile {
    width: 100%;
    height: 230px !important;
  }
  .premium_plus,
  .premium,
  .basic {
    height: auto;
    & .tileHeader {
      display: table;
      min-height: 42px;
    }
    & .details {
      height: 115px;
      border-left: 0 !important;
      border-top: 1px solid #ddd;
      & .addedBy {
        position: absolute;
        left: 10px;
        bottom: 3px;
        margin: 5px 0;
        width: calc(100% - 20px);
      }
      & .description {
        margin-top: 0;
      }
      & .tileTitle {
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
      }
    }
    & .footer {
      & .btnCont {
        & .phoneBtn {
          width: 50% !important;
        }
        & .inquiryBtn {
          width: 50% !important;
          border-right: none !important;
          & .btnImg {
            width: auto !important;
          }
          & p {
            display: block;
            padding-left: 30px;
          }
        }
        & .compareBtn_cont {
          display: none;
        }
      }
      & .priceContainer {
        & .price2 {
          color: #fff;
          font-size: 18px;
          margin: 0;
          padding: 0 10px;
          line-height: 38px !important;
        }
      }
    }
  }
  .inquiryPopCont {
    z-index: 1210;
  }
  .inquiryPop {
    width: 0;
    margin: 0 auto;
    height: 100%;
    position: relative;
  }
  .inquiryPopup {
    width: 100vw;
    height: 100%;
    margin: 0 -50vw;
    z-index: 1211;
    top: 0 !important;
    transform: translateY(0%);
    margin-top: 0;
    padding: 20px;
    padding-top: 50px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .numberPopCont {
    z-index: 1210;
  }
  .numberPop {
    width: 0;
    margin: 0 auto;
    height: 100%;
    position: relative;
  }
  .numberPopup {
    width: 100vw;
    height: 100%;
    margin: 0 -50vw;
    z-index: 122;
    top: 0;
    transform: translateY(0%);
    margin-top: 0;
    padding: 20px;
    padding-top: 40px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .paddingChange {
    padding: 0 15px;
  }
}

.slider {
  & .slick_propertyTile {
    & .slick-arrow {
      opacity: 0;
      z-index: 10 !important;
      padding: 22px;
      height: 75px !important;
      width: 75px !important;
      transition: 0.3s ease;
      & img {
        filter: brightness(0) invert(1) drop-shadow(0 0 5px #00000099);
      }
    }
    & .slick-arrow::before {
      content: '' !important;
    }
    & .slick-next {
      transform: rotate(-90deg) translateX(50%);
      right: 0;
    }
    & .slick-prev {
      transform: rotate(90deg) translateX(-50%);
      left: 0;
    }
  }
}

.slider:hover {
  & .slick_propertyTile {
    & .slick-arrow {
      opacity: 1;
    }
  }
}

@media only screen and (max-width: 1199px) {
  .slider {
    & .slick_propertyTile {
      & .slick-arrow {
        opacity: 1;
      }
    }
  }
}

.row .col {
  // height: 100px;
  background: white;
}
.buttonContainers {
  margin-top: 10px;
}

.logoWrapper {
  width: 25px;
  display: inline-block;
  overflow: hidden;
  margin-top: 5px;
}

.svg {
  width: 100%;
  height: 25px;
}

.redSvg {
  width: 100%;
  fill: #ef5350;
  height: 25px;
}

.verified {
  width: 100%;
  fill: #4dbd74;
  height: 25px;
  cursor: pointer;
}

.nonVerified {
  width: 100%;
  fill: #c6c9ce;
  height: 25px;
  cursor: pointer;
}

.propsureArms {
  margin-left: -76px;
  width: 26px;
}

.propsureAdmin {
  margin-left: -29px;
  width: 26px;
}

.propsure-expired-arms {
  margin-left: -72px;
  filter: grayscale(100%);
  width: 26px;
}
.propsure-expired-wanted {
  margin-left: -85px;
  filter: grayscale(100%);
  width: 26px;
}
.propsure-expired-admin {
  margin-left: -29px;
  filter: grayscale(100%);
  width: 26px;
}
a {
  color: $graana-red;
}

.status-primary {
  background: #ceecfc;
  color: #28566a;
}

.status-success {
  background: #cefce2;
  color: #286a4f;
}
.tooltip {
  pointer-events: none;
}
.status-warning {
  background: #fef3c6;
  color: #916121;
}

.status-danger {
  background: #fde0e2;
  color: #7a3635;
}

tr.disabled {
  background: rgba(239, 83, 80, 0.5) !important;
}

tr.imageBankPlot {
  background: rgba(255, 255, 204, 1) !important;
}

tr.rowFilter {
  background: rgb(212, 235, 242) !important;
}
tr.wantedWarning {
  background: rgba(255, 255, 204, 0.2) !important;
}
tr.wantedDanger {
  background: rgba(239, 83, 80, 0.2) !important;
}
tr.user-disabled {
  background: #a9a9a9 !important;
}

.graana-red {
  color: $graana-red;
}

.graana-green {
  color: $graana-green;
}

.custom-link {
  padding: 0;
  color: inherit !important;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.noSelect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                        supported by Chrome and Opera */
}

.watermark-checkbox {
  margin-top: 10px;
}
.customselect {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
.custom-city {
  .Select-arrow {
    display: none !important;
  }
}
.searchby-select {
  max-width: 100%;
}
.date-filter-select {
  width: 200px;
  display: inline-block;
  margin-bottom: -12px;
}
.searchby-select-properties {
  max-width: 86%;
}
.department-select {
  width: 200px;
}
.font-size-test {
  font-size: 10px;
}
.city-select-properties {
  width: 86px;
  left: 92px;
  position: absolute;
}
.area-select-properties {
  position: absolute;
  width: 78px;
  left: 177px;
}
.city-select {
  width: 90px;
  left: 82px;
  position: absolute;
}
.city-select-geotagging {
  width: 130px;
  margin-right: 5;
}
.area-select-geotagging {
  width: 130px;
}
.area-select {
  position: absolute;
  width: 90px;
  left: 170px;
}
.job-select {
  height: 36px;
  margin-right: 5px;
  background-color: #efefef;
}
.custom-nav {
  margin-left: 10px !important;
}
.wanted-filter {
  width: 345px;
  // position: absolute;
  // right: 0;
  // top: 13px;
}
.cus-z-index {
  // z-index: 111;
  position: sticky;
  right: 0;
  padding: 0px !important;

  & > div {
    opacity: 0;
    // width: 115px;
    //background-color: #e0edfd;
  }
}
.hover__for__btns {
  &:hover {
    background-color: #eee;
    .cus-z-index {
      & > div {
        opacity: 1;
      }
    }
  }

  &:focus-within,
  &:focus {
    background-color: #eee;
    .cus-z-index {
      z-index: 1;
      & > div {
        opacity: 1;
      }
    }
  }
}
.action-cus {
  &::after {
    display: none;
  }

  i {
    margin-left: 2px;
  }
}
.wanted-assigned-filter {
  width: 180px;
}
.property-filter {
  width: 200px;
}
.property-rejected-filter {
  width: 125px;
}
.select-round {
  border-radius: 5px;
}
.zone-city-select {
  height: 47px;
  width: 115px;
  left: 752px;
  top: 119px;
  border-radius: 8px;
}
.route-disabled {
  color: #a9a9a9;
}
.logo-wrapper {
  width: 25px;
  display: inline-block;
  overflow: hidden;
  margin-top: 5px;
}
.icon-nav {
  width: 14px;
  display: inline-block;
  // overflow: hidden;
  height: 14px;
  margin-right: 9px;
  margin-left: 3px;
}
.svg {
  width: 100%;
  height: 25px;
}
.verified {
  width: 100%;
  fill: #4dbd74;
  height: 25px;
  cursor: pointer;
}
.non-verified {
  width: 100%;
  fill: #c6c9ce;
  height: 25px;
  cursor: pointer;
}
.lead-stats-container {
  height: 150px;
  background-color: white;
}
.hide-button {
  display: none;
}
.button1 {
  background-color: #fff;
  border: none;
}
.button1:hover {
  background-color: #fff;
  color: $graana-red;
  border: none;
}
.button1:disabled {
  background-color: #fff;
  color: $graana-red;
}
.zone-select {
  height: 47px;
  border-radius: 8px;
  width: 100%;
  border: 1px solid #c2cfd6;
  appearance: none;
  // -webkit-appearance: none;
  // -moz-appearance: none;
  padding: 0.8rem 0.5rem;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1em;
  background-position: right 1rem center;
}
.zone-select-selected {
  height: 47px;
  border-radius: 8px;
  width: 100%;
  border: 1px solid #c2cfd6;
  appearance: none;
  // -webkit-appearance: none;
  // -moz-appearance: none;
  padding: 0.8rem 0.5rem;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='rgb(55,162,248)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1em;
  background-position: right 1rem center;
  background-color: #37a2f8;
  color: white;
}

.cus-dropdown {
  &:hover {
    background-color: #f6f6f6;
  }
  &:focus {
    color: black;
  }
}
.selected {
  background-color: #ebf6ff;
  color: #37a2f8;
  &:hover {
    color: #37a2f8;
    background-color: #ebf6ff;
  }
}
tr:hover .cust_btn {
  opacity: 1;
}
.cust_btn {
  background-color: white;
  border-radius: 4px;
  color: #37a2f8;
  border: 1px solid #37a2f8;
  opacity: 0;
  &:hover {
    background-color: #37a2f8;
    color: white;
  }
}
.area-properties-select {
  position: absolute;
  width: 90px;
  left: 236px;
}
.search-btn {
  position: absolute;
  right: -35px;
}
.area-submit {
  position: absolute;
  right: 93px;
}

.general-submit {
  position: absolute;
  right: 98px;
}

.commentdate {
  display: block;
  margin-top: 10px;
  font-size: 10px;
  color: #dd5050;
}

.inlineInput {
  background-color: transparent;
  border: 0px solid #c2cfd6;
  padding: 8px;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
}
.inlineInput:hover {
  background-color: #ffffff;
  cursor: pointer;
  border: 1px solid #c2cfd6;
}
.unreadCount {
  position: relative;
  top: 8px;
  width: 70px;
  border: 1px solid red;
  border-radius: 25px;
  padding: 2px 8px;
  color: red;
}

.customAnchor {
  color: $graana-red;
  cursor: pointer;

  &:hover {
    color: $graana-hover;
    text-decoration: underline;
  }
}

.cursorPointer {
  cursor: pointer;
}

.sidebar {
  .sidebar-minimizer-container {
    flex: 0 0 49px;
    position: relative;
  }
  .nav {
    position: relative;
    padding-bottom: 45px;
    & > li.nav-item:last-child {
      width: 100%;
      position: absolute;
      bottom: 0;
      display: none;
    }
  }
  .sidebar-minimizer {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 49px;
    width: 50px;
    flex: unset;
    background-color: transparent;
    &::before {
      background-image: $sidebar-minimizer-hover-indicator;
    }
  }
  .logout-link {
    max-height: 50px;
    padding-top: 14px;
    padding-bottom: 14px;
  }
  .nav-link {
    &.active {
      i {
        //color: $sidebar-nav-link-active-icon-color-graana;
      }
    }
  }
  .nav-dropdown-toggle {
    user-select: none;
  }
}
.commentsMainWrap {
  position: relative;
  margin-top: 20px;
  height: 250px;
  overflow-y: scroll;
  overflow-x: hidden;

  & .commentHistoryWrap {
    position: relative;
    padding-right: 0px;

    & .userName {
      position: absolute;
      left: 0px;
      width: 40px;
      text-align: center;
      height: 40px;
      display: grid;
      align-items: center;
      border-radius: 50%;
      background-color: #fafafa;
      border: 1px solid #ebebeb;
      top: 0px;
    }

    & p {
      font-size: 12px;
      max-width: 85%;
      display: inline-block;
      background-color: #fafafa;
      padding: 10px;
      border-radius: 10px;
      margin-bottom: 10px;
      border: 1px solid #ebebeb;
      text-align: left;
    }
  }

  & .commentWrap {
    position: relative;
    padding-left: 50px;
    padding-right: 0px;

    & .userName {
      position: absolute;
      left: 0px;
      width: 40px;
      text-align: center;
      height: 40px;
      display: grid;
      align-items: center;
      border-radius: 50%;
      background-color: #fafafa;
      border: 1px solid #ebebeb;
      top: 0px;
    }

    & p {
      font-size: 12px;
      max-width: 85%;
      display: inline-block;
      background-color: #fafafa;
      padding: 10px;
      border-radius: 10px;
      margin-bottom: 10px;
      border: 1px solid #ebebeb;
      text-align: left;
    }
  }

  & .adminComment {
    text-align: right;
    padding-right: 55px;
    padding-left: 0px;

    & .userName {
      right: 5px;
      left: auto;
      background-color: #ef53500f;
      border: 1px solid #ef535014;
    }

    & p {
      background-color: #ef53500f;
      border-color: #ef535014;
    }
  }
}

.commentInputMain {
  position: relative;
  padding-left: 50px;
  margin-top: 10px;

  & input {
    box-shadow: 0 0 0 !important;
    background-color: #fafafa;
    border: 1px solid #ebebeb;
    min-height: 40px;

    &:focus {
      border-color: #cbcbcb;
    }
  }

  & .userName {
    position: absolute;
    left: 0px;
    width: 40px;
    text-align: center;
    height: 40px;
    display: grid;
    align-items: center;
    border-radius: 50%;
    background-color: #fafafa;
    border: 1px solid #ebebeb;
    top: 0px;
  }
  & .commentBtn {
    position: absolute;
    outline: none !important;
    right: 5px;
    top: 5px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 0px;
    /* border: 1px solid #ef5350; */
    color: #acacac;
    background-color: #ffffff;
    box-shadow: 0 0px 2px 1px rgba(0, 0, 0, 0.03);

    &:hover {
      color: #ef5350;
    }
  }
}

.commentBtn {
  outline: none !important;
  border-radius: 50%;
  border: 0px;
  color: #acacac;
  background-color: #ffffff;
  box-shadow: 0 0px 2px 1px rgba(0, 0, 0, 0.03);
}

.userSearch {
  position: relative;
  width: 25%;
  margin-left: 15px;

  & input {
    box-shadow: 0 0 0 !important;
    background-color: #fafafa;
    border: 1px solid #ebebeb;
    min-height: 38px;

    &:focus {
      border-color: #cbcbcb;
    }
  }

  & .userName {
    position: absolute;
    left: 0px;
    width: 40px;
    text-align: center;
    height: 40px;
    display: grid;
    align-items: center;
    border-radius: 50%;
    background-color: #fafafa;
    border: 1px solid #ebebeb;
    top: 0px;
  }
  & .commentBtn {
    position: absolute;
    outline: none !important;
    right: 15px;
    top: 5px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 0px;
    /* border: 1px solid #ef5350; */
    color: #acacac;
    background-color: #fafafa;
    box-shadow: 0 0px 2px 1px rgba(0, 0, 0, 0.03);

    &:hover {
      color: #ef5350;
    }
  }
}

// .sidebar-minimized {
//   .sidebar {
//     .nav {
//       & > li.nav-item:last-child {
//         display: block;
//       }
//     }
//     .sidebar-minimizer {
//       position: absolute;
//       background-color: #29363d;
//       height: 49px;
//       width: 100%;
//       left: 0;
//       &::before {
//         background-image: $sidebar-minimizer-indicator;
//       }
//       &:hover {
//         &::before {
//           background-image: $sidebar-minimizer-hover-indicator;
//         }
//       }
//     }
//     .logout-link {
//       display: none;
//     }
//   }
// }

.app-header.navbar {
  .navbar-brand {
    .svg-logo-icon {
      display: none;
    }
  }
}

.brand-minimized {
  .app-header.navbar {
    .navbar-brand {
      padding: 7px;
      .svg-logo {
        display: none;
      }
      .svg-logo-icon {
        display: inline-block;
      }
    }
  }
}
.profile {
  background-color: #fff;
  margin-right: 20px;
  padding: 0px;
  text-align: left;
  border-radius: 12px;
}

.toogleSidebarIcon {
  color: white;
  box-shadow: none;
  padding-right: 0px;
  z-index: 1;
  background-color: #715fe2 !important;
  position: relative;
  border: hidden;
  width: 31px;
  height: 31px;
  top: 60px;
  right: 18px;
}

@media screen and (max-width: 600px) {
  div.responsiveProfile {
    display: flex;
    margin-top: 12px;
  }
  .responsiveGraanaText {
    display: flex !important;
  }
  .nav-item {
    margin-top: 5px !important;
  }
  .topLine {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    width: 80%;
    position: absolute;
    left: 20px;
  }
  .bottomLine {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    width: 80%;
    position: absolute;
    left: 20px;
    top: 8%;
    z-index: 1;
  }
  div.graanaLogo {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    bottom: 12%;
    left: 20px;
    width: 80%;
    position: absolute;
  }
}
.responsiveProfile {
  display: none;
}
.responsiveGraanaText {
  display: none;
}
.admin-heading {
  color: #29363d;
  margin-top: 12px;
}

.sideBarIcon {
  display: none;
}

.dropdown-no-border {
  border: none;
}

.table-responsive {
  background: #ffffff;
  margin-bottom: 1rem;
  border: 1px solid rgba(158, 158, 158, 0.5);
}

.table {
  margin-bottom: 0;
  border-color: 1px solid #a4b7c1;
  --bs-table-hover-bg: #f6f6f6;
}
.table thead {
  border-bottom: 0.13rem solid #e7e8e8;
  height: 54px;
}
.table .newThead {
  height: 56px !important;
}
.table thead th {
  padding-left: 32px;
  border-bottom: none;
  border-top: none;
  white-space: nowrap;
}
.table tbody tr {
  height: 52px;
}

.table td {
  padding-left: 32px;
  vertical-align: middle;
  border-bottom: 1px solid rgba(158, 158, 158, 0.5);
}

.btn-info {
  color: #ffffff !important;
}

.btn-custom-reporting:hover {
  color: #ffffff;
}

.btn-info:hover {
  color: #ffffff;
}
.btn-dropdown {
  background-color: #fff;
}
.btn-dropdown:hover {
  background-color: #f0f3f5;
}
.modal-content {
  border-radius: 8px;
  box-shadow: 1px 1px 10px 4px gray;
}
.activity-modal {
  border-radius: 0;
}

.pagination {
  display: inline-flex;
  max-width: 100%;
  overflow-x: auto;
  padding-top: 4px;
  margin-top: -4px;
  padding-bottom: 1rem;
  margin-bottom: 0;
  color: black;
}
.cus-pagination {
  display: inline-flex;
  max-width: 100%;
  overflow-x: auto;
  align-items: center;
  padding-top: 4px;
  margin-top: -4px;
  list-style: none;
  padding-bottom: 1rem;
  margin-bottom: 0;
  gap: 10px;
}
.cus-pagination .active {
  color: white !important;
  background-color: #37a2f8;
  text-decoration: none;
}
.cus-pagination .break-me {
  color: #9e9e9e !important;
}
.cus-pagination .next-page {
  color: #37a2f8;
  font-weight: bold;
  margin-left: 8px;
  text-decoration: none;
}
.cus-pagination .pre-page {
  margin-right: 8px;
  color: #9e9e9e;
  font-weight: bold;
  text-decoration: none;
}
.cus-pagination .page-num {
  padding: 6px 13px;
  border-radius: 4px;
  text-decoration: none;
  color: #9e9e9e;
  &:hover {
    background-color: rgba(55, 162, 248, 0.25);
  }
}

.searchBar {
  & ::placeholder {
    color: #9e9e9e;
  }
  position: relative;
  width: 20%;
  & input {
    box-shadow: 0 0 0 !important;
    background: rgba(158, 158, 158, 0.1);
    border: 1px solid rgba(158, 158, 158, 0.5);
    box-sizing: border-box;
    border-radius: 8px;
    min-height: 47px;
    &:focus {
      border-color: #cbcbcb;
    }
  }
  & .commentBtn {
    position: absolute;
    outline: none !important;
    right: 15px;
    top: 9px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: none !important;
    background-color: inherit;
    color: #37a2f8;
    &:hover {
      font-weight: bolder;
    }
  }
}

.loadingBtn {
  position: absolute;
  right: 15px;
  top: 15px;
  color: #37a2f8;
}

.additionalPhonesA {
  padding-left: 0px;
}

.additionalPhonesB {
  margin-top: 4px;
}

.wantedComment {
  padding-left: 0px;
  margin-top: 65px;
}

.marginFix {
  margin-right: 5px;
}

/* select.form-control:not([size]):not([multiple]) {
  height: calc(2.0625rem + 3px);
} */

input[type='file'].form-control {
  height: auto;
}

@media (max-width: 991px) {
  .app-header.navbar {
    .navbar-brand {
      .svg-logo-icon {
        max-width: 40px;
      }
    }
  }
}

.pagination-page-size {
  float: right;
  width: auto;
  margin-bottom: 15px;
}

.download-all {
  position: absolute;
  width: auto;
  margin-bottom: 15px;
  right: 120px;
}

.page-bulk-actions {
  float: left;
  width: auto;
  display: flex;
  margin-bottom: 15px;
}

.custom-select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

div.sticky-alert {
  position: -webkit-sticky;
  position: sticky;
  top: 100px;
  z-index: 1;
}

.button-alert {
  background: none !important;
  border: none;
  padding: 0 !important;
  color: #069;
  text-decoration: underline;
  cursor: pointer;
}

.export-data {
  font-size: 80%;
  margin-left: 10px;
  cursor: pointer;
  i {
    padding: 0 5px;
    color: #3e515b;
    &.red {
      color: #ef5350;
    }
    &:hover {
      color: #ef5350;
    }
  }
}

.amenitiesBox {
  border: 1px solid #e5e5e5;
  border-radius: 2px;
  margin-bottom: 5px;
  &:hover {
    background-color: #efefef;
    cursor: pointer;
  }
}

.pagination-datatables li span.customPage,
.pagination li span.customPage {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #20a8d8;
  background-color: #fff;
  border: 1px solid #a4b7c1;
}

.pagination-datatables li span.customPage:not(:disabled):not(.disabled),
.pagination li span.customPage:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.pagination-datatables li span.customPage:hover,
.pagination li span.customPage:hover {
  color: #167495;
  text-decoration: none;
  background-color: #c2cfd6;
  border-color: #a4b7c1;
}

.pagination-datatables li span.customPage:focus,
.pagination li span.customPage:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(32, 168, 216, 0.25);
}

#zone-select {
  .Select-control {
    padding: 1px;
    border-radius: 3px;
    border-color: #768791;
    margin-bottom: 10px;
    width: 100%;
    .Select-placeholder {
      color: #697278;
    }
  }

  .Select {
    border-radius: 0px;
  }
  .Select-menu-outer {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    .Select-option:last-child {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.Select-multi-value-wrapper .Select-input > input {
  padding: 8px 0 8px;
}

.DateRangePicker_picker {
  top: 55px !important;
}

.SingleDatePicker_picker__directionLeft {
  top: 46px !important;
}

.DateInput_fang {
  top: 35px !important;
}

.custom-select:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(32, 168, 216, 0.25);
}

.hoveredCell {
  .showOnHover {
    display: none;
  }
  &:hover {
    .showOnHover {
      display: inline-block;
    }
    .hideOnHover {
      display: none !important;
    }
  }
}

.media_icon + .media_icon {
  margin-left: 5px;
}

.graySVGIcon {
  fill: lightgray !important;
}

.currentImagesTiles {
  .hoverTitle {
    display: none;
  }
  &:hover {
    .hoverTitle + .normalTitle {
      display: none;
    }
    .hoverTitle {
      display: block;
    }
  }
}

.webkitLineClamp2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.aside-menu-content {
  max-height: calc(100% - 95px);
  position: relative;
  overflow: auto;
  padding-bottom: 10px;

  .filter-item {
    padding: 15px 15px;
    border-bottom: 1px solid #c2cfd6;

    select {
      margin-bottom: 5px;
    }
    .add-subtract-identifier {
      display: inline-block;
      margin: 5px 0 0 -17px;
      color: $graana-red;
      position: absolute;
      font-size: 20px;
      font-weight: bold;
      padding: 0 10px;
      background: #ffffff;
      line-height: 1;
      width: 34px;
      cursor: pointer;
    }
    &:first-child {
      padding-top: 5px;
    }
  }
}

.aside-menu-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  .apply-btn {
    height: 49px;
  }
  .filter-buttons {
    button {
      width: 50%;
      display: inline-block;
    }
  }
}

.tooltip-disabled {
  display: inline-block;
}

.tooltip-disabled [disabled] {
  pointer-events: none;
}

.price-label-container {
  position: relative;
  & .price-label {
    position: absolute;
    top: 3px;
    right: 18px;
    padding: 4px;
    background: #f1f1f1;
    border-radius: 0 5px 5px 0;
    color: #00897b;
    &:before,
    &:after {
      content: ' ';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
    &:before {
      left: -14px;
      border-right: 15px solid #f1f1f1;
      border-top: 16px solid transparent;
      border-bottom: 16px solid transparent;
    }
    &:after {
      left: -5px;
      width: 5px;
      height: 5px;
      background: #fff;
      border-radius: 50%;
    }
  }
}

/* Department Routes Styles */
.route-access-actions {
  margin-right: 15px;
  display: inline-block;
  width: 12px;
  font-weight: bold;
}
.route-access-action-items {
  margin-right: 15px;
}
.collapse-able-div {
  border-left: 1px solid #a4b7c1;
  border-top: none;
  font-weight: normal;
  padding-left: 10px;
}
.cursor-pointer {
  cursor: pointer;
}
.route-container {
  display: flex;
  justify-content: space-between;
}
.margin-right-15 {
  margin-right: 15px;
}
.flex-1-1-0 {
  flex: 1 1 0;
}
.text-capitalize {
  text-transform: capitalize;
}
.pac-container {
  z-index: 1000000 !important;
}
