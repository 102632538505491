/** @format */

.alignLabel {
  text-align: right;
  margin-bottom: 20px;
  margin-top: -10px;
}

.forgetLabel {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #f24e1e;
}

.heading {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 38px;
  color: #000000;
}

.text {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 21px;
  text-align: center;
  color: #9e9e9e;
}

.versionLabel {
  font-size: 10;
  position: relative;
  top: 22;
}

.resendText {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #615c5c;
  display: inline-flex;

  & > div {
    padding-left: 5px;
    padding-bottom: 5px;
    line-height: 18px !important;
  }

  button {
    background: none !important;
    border: none !important;
    margin: 0 !important;
    padding: 0 !important;
    font-family: 'Rubik' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 15px !important;
    line-height: 18px !important;
    color: #f24e1e !important;
  }
}

.alignOtpField {
  margin-left: 35px;
  margin-bottom: 15px;
}

.otpBox {
  width: 50px !important;
  height: 50px;
  margin-left: 20px;
  background: #e8f0fe;
  border: 0.5px solid #9e9e9e;
  border-radius: 6px;
}

.eyeIcon {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  font-size: 20px;
  margin-left: -26px !important;
  z-index: 9999;
  color: #777575;
}
