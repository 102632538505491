/** @format */

.input-group-prepend,
.input-group-append {
  white-space: nowrap;
  vertical-align: middle; // Match the inputs
}
.input-group-addon,
.input-group-btn {
  min-width: 40px;
  white-space: nowrap;
  vertical-align: middle; // Match the inputs
}
