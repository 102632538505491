/** @format */

// Temp fix for reactstrap
.app-header {
  .navbar-nav {
    .dropdown-menu-right {
      right: auto;
    }
  }
}
