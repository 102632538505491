/** @format */

.app-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 $spacer;
  color: $footer-color;
  background: $body-bg;
}
