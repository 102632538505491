/** @format */

.leaflet-container {
  width: 100%;
  height: 50vh;
}
.ms-10 {
  margin-left: 10px;
}

.space-0 {
  margin-bottom: 10px;
}

.space-1 {
  margin-bottom: 20px;
}

.space-2 {
  margin-bottom: 30px;
}

.space-3 {
  margin-bottom: 40px;
}

.space-4 {
  margin-bottom: 50px;
}

.space-5 {
  margin-bottom: 60px;
}

.space-5 {
  margin-bottom: 70px;
}

/*.navbar-fixed-top {
  border: 0;
}*/

.x_panel {
  position: relative;
  width: 100%;
  margin-bottom: 10px;
  padding: 10px 17px;
  display: inline-block;
  background: #fff;
  border: 1px solid #e6e9ed;
  -webkit-column-break-inside: avoid;
  -moz-column-break-inside: avoid;
  opacity: 1;
  transition: all 0.2s ease;
  border-radius: 5px;
}

.x_title {
  border-bottom: 2px solid #e6e9ed;
  padding: 1px 5px 6px;
  margin-bottom: 10px;
}

.dropzone {
  min-height: 100px;
  border: 1px solid #e5e5e5;
}

.ln_solid {
  border-top: 1px solid #e5e5e5;
  color: #ffffff;
  background-color: #ffffff;
  height: 1px;
  margin: 20px 0;
}

.navbar-right {
  margin-right: 0;
}

.x_content {
  padding: 0 5px 6px;
  position: relative;
  width: 100%;
  float: left;
  clear: both;
  margin-top: 5px;
}

.form-horizontal .control-label {
  padding-top: 8px;
  text-align: right;
  font-weight: bold;
}

.form-horizontal .form-group {
  margin-right: 0;
  margin-left: 0;
}

.form-group {
  margin-bottom: 10px !important;
}

.form-control {
  border-radius: 5px;
  width: 100%;
}
.form-select {
  border-radius: 5px;
  width: 100%;
}

/*.sidebar {
  display: none;
}

@media (min-width: 768px) {
  .sidebar {
    position: fixed;
    top: 51px;
    bottom: 0;
    left: 0;
    z-index: 1000;
    display: block;
    padding: 15px;
    overflow-x: hidden;
    overflow-y: auto; !* Scrollable contents if viewport is shorter than content. *!
    background-color: #f5f5f5;
    border-right: 1px solid #eee;
  }
}*/

.nav-sidebar {
  margin-right: -21px; /* 20px padding + 1px border */
  margin-bottom: 20px;
  margin-left: -20px;
}

.side_nav {
  padding: 2px;
  padding-right: 20px;
  padding-left: 20px;
  font-size: 16px;
  height: 30px;
  font-weight: normal;
  border-bottom: 1px solid #ddd;
}

.side_nav > .active > a,
.side_nav > .active > a:hover,
.side_nav > .active > a:focus {
  color: #fff;
  background-color: #fff;
}

.side_nav:hover {
  background-color: #fff;
}

.active {
  background-color: #fff;
}

.side_nav:active {
  background-color: #fff;
}

.nav-sidebar > .active > a,
.nav-sidebar > .active > a:hover,
.nav-sidebar > .active > a:focus {
  color: #fff;
  background-color: #fff;
}

/*.main {
  padding: 20px;
}
@media (min-width: 768px) {
  .main {
    padding-right: 40px;
    padding-left: 40px;
  }
}*/

.placeholder {
  margin-bottom: 20px;
}

.inlineDisplay {
  display: inline;
}

.SingleDatePicker {
  position: relative;
  display: block;
}

.DateInput {
  font-weight: 200;
  font-size: 18px;
  line-height: 7px;
  color: #757575;
  margin: 0;
  padding: 4px 8px;
  background: #fff;
  position: relative;
  display: inline-block;
  width: 125px;
  vertical-align: middle;
  border-radius: 2px;
}

.DateInput_input {
  padding: 0;
  font-size: 16px;
  font-weight: 400;
}

.SingleDatePickerInput {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}

.DateRangePickerInput_clearDates {
  border-radius: unset !important;
  margin: 0;
}

/*.btn-group-lg > .btn, .btn-lg {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 3px;
}*/

.chart {
  border: 1px solid #dddddd;
  padding: 15px 35px 15px 35px;
}

.sideNavHeading {
  font-size: 30px;
  font-weight: bold;
}

.logOut {
  position: absolute;
  bottom: 0;
}

.search-btn {
  border-radius: 0px;
}

.marginTop {
  margin-top: 20px;
}

.modalInput {
  padding: 5px;
  display: block;
  margin-bottom: 10px;
  width: 100%;
}

.red {
  color: #ef5350;
}

.no-padding {
  padding: 0px;
}

.no-margin {
  margin: 0px;
}

.agencyUserTile {
  padding: 15px;
  border: 1px solid #ddd;
  /*margin: 10px;*/
  vertical-align: middle;
}

.agencyUserTile:hover {
  box-shadow: 1px 1px 3px #ddd;
}

.multi-drop-area > div {
  margin-top: -10px;
  background: #eee;
  border: 1px solid #ccc;
  border-radius: 0;
  min-height: 400px;
  position: relative;
  padding: 15px;
}

/* WILL DETERMINE STYLE WHEN DRAGGING OVER */
.md-dragging {
  border: 1px dotted #ccc !important;
}

/* WILL DETERMINE STYLE WHEN ACTIVE IN DRAGGING LIST */
.md-active {
  background: #eee;
  border-radius: 3px;
}

.form-horizontal .control-label.text-start {
  text-align: left;
}

.multi-drag-container.no-user-select {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.removeBtnStyle {
  position: relative;
}

.removeBtn {
  position: absolute;
  left: 101%;
  bottom: 0px;
}

.tartairy-img img {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.showingTartairyImg {
  width: 80px;
  height: 80px;
  object-fit: cover;
  margin-top: 10px;
}
