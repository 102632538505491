/** @format */

// Bootstrap overrides

//
// Color system
//

$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #c2cfd6;
$gray-300: #6c757d;
$gray-400: #869fac;
$gray-500: #678898;
$gray-600: #536c79;
$gray-700: #3e515b;
$gray-800: #212529;
$gray-900: #151b1e;
$black: #000 !default;

$blue: #0d6efd;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #dc3545;
$red-light: #f87a79;
$orange: #f8cb00;
$yellow: #ffc107 !default;
$green: #198754;
$teal: #20c997 !default;
$cyan: #63c2de;
$graana-red: #ef5350;
$info: rgba(55, 162, 248, 0.1);

$colors: (
  blue: $blue,
  indigo: $indigo,
  purple: $purple,
  pink: $pink,
  red: $red,
  orange: $orange,
  yellow: $yellow,
  green: $green,
  teal: $teal,
  cyan: $cyan,
  white: $white,
  gray: $gray-600,
  gray-dark: $gray-800,
  graana-red: $graana-red,
);

$theme-colors: (
  primary: $blue,
  secondary: $gray-300,
  success: $green,
  info: $info,
  warning: $yellow,
  danger: $red,
  danger-light: $red-light,
  light: $gray-100,
  dark: $gray-800,
  blue: $blue,
  indigo: $indigo,
  purple: $purple,
  pink: $pink,
  red: $red,
  orange: $orange,
  yellow: $yellow,
  green: $green,
  teal: $teal,
  cyan: $cyan,
  graana-red: $graana-red,
  gray-100: $gray-100,
  gray-200: $gray-200,
  gray-300: $gray-300,
  gray-400: $gray-400,
  gray-500: $gray-500,
  gray-600: $gray-600,
  gray-700: $gray-700,
  gray-800: $gray-800,
  gray-900: $gray-900,
);

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-transitions: true;
$enable-rounded: false;

// Body
//
// Settings for the `<body>` element.

//$body-bg: #f1f1f1;
$body-bg: white;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-size-base: 0.875rem;

// Breadcrumbs

$breadcrumb-bg: #fff;
$breadcrumb-margin-bottom: 1.5rem;

// Cards

$card-border-color: $gray-200;
$card-cap-bg: $gray-100;

// Dropdowns

$dropdown-padding-y: 0;
$dropdown-border-color: $gray-200;
$dropdown-divider-bg: $gray-100;

// Buttons

$btn-secondary-border: $gray-300;

// Progress bars

$progress-bg: $gray-100;

// Tables

$table-bg-accent: $gray-100;
$table-bg-hover: $gray-100;

// Forms

$input-group-addon-bg: $gray-100;
$input-border-color: $gray-200;
$input-group-addon-border-color: $gray-200;
