/** @format */

// Override Boostrap variables
@import 'bootstrap-variables';

// Import Bootstrap source files
@import 'node_modules/bootstrap/scss/bootstrap';

// Override core variables
@import 'core-variables';

// Import core styles
@import 'core/core';

// Import UI Kits
@import 'uikits/uikits';

// Old Dashboard styles
@import 'dashboard';

// Custom styles
@import 'custom';
