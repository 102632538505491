/** @format */

.loaderOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 99999;
  min-height: 200px;
  &.fixed {
    position: fixed;
  }
  &.opaque {
    background-color: rgba(255, 255, 255, 1);
  }
  & .logoCenter {
    position: absolute;
    top: 50%;
    left: 50%;
    //transform: translate(-50%, -50%);
    margin-top: -80px;
    margin-left: -50px;
    & .logoWrapper {
      width: 100px;
      height: 100px;
      animation: heartbeat 1s infinite;
      & svg {
        fill: #ef5350;
        width: 100%;
        height: 100%;
      }
    }
  }
}

@keyframes heartbeat {
  0% {
    transform: scale(0.9);
  }
  10% {
    transform: scale(1);
  }
  20% {
    transform: scale(0.9);
  }
  30% {
    transform: scale(1);
  }
  40% {
    transform: scale(0.9);
  }
  80% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(0.9);
  }
}
