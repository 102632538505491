/** @format */

.bg-primary,
.bg-success,
.bg-info,
.bg-warning,
.bg-danger,
.bg-inverse {
  color: #fff;
}
